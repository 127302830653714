var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "crud-table",
    {
      key: _vm.api,
      ref: "table",
      staticClass: "orders",
      attrs: {
        api: _vm.api,
        url: _vm.rowURL,
        target: "_blank",
        deleteRow: false,
        filter: _vm.filter,
        order: _vm.assignFilter,
        loadOptions: "user-dashboard",
        columns: _vm.columns,
        name: { singular: "Element", plural: "Elementen" },
        storeKey: "user-dashboard/list",
        checkable: _vm.isRequests,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "filters",
            fn: function ({ options }) {
              return _c("table-filters", {
                attrs: {
                  type: _vm.type,
                  options: options,
                  "data-v-step": "filters",
                },
                model: {
                  value: _vm.filters,
                  callback: function ($$v) {
                    _vm.filters = $$v
                  },
                  expression: "filters",
                },
              })
            },
          },
          {
            key: "footer",
            fn: function ({ checked, options }) {
              return _vm.isRequests
                ? _c("div", {}, [
                    _c(
                      "div",
                      { staticClass: "level level-left" },
                      [
                        _c(
                          "toggle-switch",
                          {
                            staticClass: "level-item",
                            model: {
                              value: _vm.requestAction.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.requestAction, "type", $$v)
                              },
                              expression: "requestAction.type",
                            },
                          },
                          [
                            _c("option", { attrs: { value: "status" } }, [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("import_contacts"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "deadline" } }, [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("date_range"),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.requestAction.type === "status"
                          ? [
                              options.status
                                ? _c("multiselect", {
                                    staticClass: "level-item is-medium",
                                    attrs: {
                                      options: options.status,
                                      "track-by": "key",
                                      label: "value",
                                    },
                                    model: {
                                      value: _vm.requestAction.status,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.requestAction,
                                          "status",
                                          $$v
                                        )
                                      },
                                      expression: "requestAction.status",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "button is-primary",
                                  attrs: {
                                    disabled:
                                      checked.length === 0 ||
                                      !_vm.requestAction.status,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.changeStatuses()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    Status von " +
                                      _vm._s(checked.length) +
                                      " Leistungen ändern\n                "
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c("input-date", {
                                staticClass: "level-item is-medium",
                                model: {
                                  value: _vm.requestAction.deadline,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.requestAction, "deadline", $$v)
                                  },
                                  expression: "requestAction.deadline",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "button is-primary",
                                  attrs: {
                                    disabled:
                                      checked.length === 0 ||
                                      !_vm.requestAction.deadline,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.changeDeadlines()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    Optionstermin von " +
                                      _vm._s(checked.length) +
                                      " Leistungen ändern\n                "
                                  ),
                                ]
                              ),
                            ],
                      ],
                      2
                    ),
                  ])
                : _vm._e()
            },
          },
          {
            key: "head:requests",
            fn: function ({ subrowsVisible }) {
              return [
                _c(
                  "button",
                  {
                    staticClass: "requests-count is-primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.toggleRows.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons is-transform-transition",
                        class: { "is-rotated": subrowsVisible },
                      },
                      [_vm._v("keyboard_arrow_down")]
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "duration",
            fn: function ({ row }) {
              return [
                _vm._v("\n        " + _vm._s(row.duration) + " Tage\n    "),
              ]
            },
          },
          {
            key: "requests",
            fn: function ({ row }) {
              return [
                row && row.requests
                  ? _c(
                      "button",
                      {
                        staticClass: "requests-count",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.toggleRow(row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.requests.length) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "requests.info.personsAsked",
            fn: function ({}) {
              return [_vm._v("-")]
            },
          },
          {
            key: "requests.info.personsReserved",
            fn: function ({}) {
              return [_vm._v("-")]
            },
          },
          {
            key: "requests.requestStatus",
            fn: function ({}) {
              return [_vm._v("-")]
            },
          },
          {
            key: "requests.optionTodo",
            fn: function ({}) {
              return [_vm._v("-")]
            },
          },
          {
            key: "days",
            fn: function ({ row }) {
              return row && row.days
                ? [_vm._v(_vm._s(row.days.length) + " Tage")]
                : undefined
            },
          },
          {
            key: "info.personsAsked",
            fn: function ({ row }) {
              return [_vm._v(_vm._s(_vm.getPax(row, "askedAmount")))]
            },
          },
          {
            key: "info.personsReserved",
            fn: function ({ row }) {
              return [_vm._v(_vm._s(_vm.getPax(row, "reservedAmount")))]
            },
          },
          {
            key: "order.client.shortName",
            fn: function ({ row }) {
              return [
                _c(
                  "popover",
                  { staticClass: "cell-content", attrs: { trigger: "hover" } },
                  [
                    _c("span", { staticClass: "is-truncated" }, [
                      _vm._v(_vm._s(row.order.client.shortName)),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "popover" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(row.order.client.shortName) +
                          "\n            "
                      ),
                    ]),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "hotel.name",
            fn: function ({ row }) {
              return [
                _c(
                  "popover",
                  { staticClass: "cell-content", attrs: { trigger: "hover" } },
                  [
                    _c("request-hotel-name", {
                      staticClass: "is-truncated",
                      attrs: { row: row },
                    }),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "popover" },
                      [_c("request-hotel-name", { attrs: { row: row } })],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "ferry.name",
            fn: function ({ row, options }) {
              return [
                _c(
                  "popover",
                  { staticClass: "cell-content", attrs: { trigger: "hover" } },
                  [
                    _c(
                      "request-ferry-name",
                      _vm._b(
                        { staticClass: "is-truncated" },
                        "request-ferry-name",
                        { row, options },
                        false
                      )
                    ),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "popover" },
                      [
                        _c(
                          "request-ferry-name",
                          _vm._b(
                            {},
                            "request-ferry-name",
                            { row, options },
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "train.name",
            fn: function ({ row, options }) {
              return [
                _c(
                  "popover",
                  { staticClass: "cell-content", attrs: { trigger: "hover" } },
                  [
                    _c(
                      "request-train-name",
                      _vm._b(
                        { staticClass: "is-truncated" },
                        "request-train-name",
                        { row, options },
                        false
                      )
                    ),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "popover" },
                      [
                        _c(
                          "request-train-name",
                          _vm._b(
                            {},
                            "request-train-name",
                            { row, options },
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "airline.name",
            fn: function ({ row, options }) {
              return [
                _c(
                  "popover",
                  { staticClass: "cell-content", attrs: { trigger: "hover" } },
                  [
                    _c(
                      "request-airline-name",
                      _vm._b(
                        { staticClass: "is-truncated" },
                        "request-airline-name",
                        { row, options },
                        false
                      )
                    ),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "popover" },
                      [
                        _c(
                          "request-airline-name",
                          _vm._b(
                            {},
                            "request-airline-name",
                            { row, options },
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "serviceDescription",
            fn: function ({ row }) {
              return [
                _c(
                  "popover",
                  { staticClass: "cell-content", attrs: { trigger: "hover" } },
                  [
                    _c("request-other-servicedescription", {
                      staticClass: "is-truncated",
                      attrs: { row: row },
                    }),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "popover" },
                      [
                        _c("request-other-servicedescription", {
                          attrs: { row: row },
                        }),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "hotel_placeholder:info.title",
            fn: function ({ row }) {
              return [
                row.hotelType
                  ? [_vm._v(_vm._s(row.hotelType.stars) + "* Hotel")]
                  : _vm._e(),
                _vm._v(" "),
                row.place
                  ? [_vm._v("in " + _vm._s(row.place.name))]
                  : row.area
                  ? [
                      _vm._v(
                        _vm._s(row.area.preposition) +
                          " " +
                          _vm._s(row.area.name)
                      ),
                    ]
                  : _vm._e(),
              ]
            },
          },
          {
            key: "client.shortName",
            fn: function ({ row }) {
              return [
                _c(
                  "client-cell",
                  { attrs: { row: row, value: row.client } },
                  [
                    row.client
                      ? [_vm._v(_vm._s(row.client.shortName))]
                      : [_vm._v("-")],
                    _vm._v(" "),
                    row.groupName
                      ? [_vm._v("(" + _vm._s(row.groupName) + ")")]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "subrow",
            fn: function ({ row, options }) {
              return row.requests &&
                row.requests.length > 0 &&
                row.subrowVisible
                ? _c(
                    "div",
                    {},
                    [
                      _c("requests-rows", {
                        attrs: { requests: row.requests, options: options },
                      }),
                    ],
                    1
                  )
                : _vm._e()
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.checked,
        callback: function ($$v) {
          _vm.checked = $$v
        },
        expression: "checked",
      },
    },
    [
      _vm._v(" "),
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "level", staticStyle: { width: "100%" } }, [
          _c(
            "div",
            { staticClass: "level-left" },
            [
              _c("h2", { staticClass: "level-item" }, [
                _vm._v("REISEÜBERSICHT:"),
              ]),
              _vm._v(" "),
              _c(
                "toggle-switch",
                {
                  attrs: { value: _vm.type, "data-v-step": "filters:type" },
                  on: { input: _vm.changeType },
                },
                [
                  _c("option", { attrs: { value: "orders" } }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("folder"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "hotel" } }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("local_hotel"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "ferry" } }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("directions_boat"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "train" } }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("directions_train"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "airline" } }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("local_airport"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "other" } }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("more_horiz"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "level-right" },
            [
              !_vm.isOrders
                ? _c("export-button", {
                    staticClass: "level-item",
                    on: { export: _vm.handleExport },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showSelectFile
                ? _c("drop-zone", {
                    staticClass: "level-item",
                    on: { documentSaved: _vm.handleOrderImport },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isOrders && !_vm.buttonIsLoading && _vm.isGrantedAdmin
                ? _c(
                    "simple-menu",
                    {
                      staticClass: "level-item",
                      attrs: {
                        material: false,
                        icon: "fa fa-file-excel-o",
                        title: "Download/Upload Excel",
                      },
                    },
                    [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.handleOrderExport(
                                "orders/generate-excel-reservations",
                                _vm.moment().format("YYYY - MM - DD") +
                                  " - Reservierungsliste.xlsx"
                              )
                            },
                          },
                        },
                        [_vm._v("Download Reservierungsliste")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleOrderExport.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("Download Reiseeinteilung")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showSelectFile = true
                            },
                          },
                        },
                        [_vm._v("Upload Reiseeinteilung")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.buttonIsLoading
                ? _c("loading", {
                    staticClass: "is-small p-0",
                    attrs: { "text-after": "", "text-in-front": "" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "level-item button is-success",
                  attrs: {
                    href: "/orders/add",
                    title: "Add a new Order",
                    target: "_blank",
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
                  _vm._v(" Neue Reise\n                "),
                ]
              ),
              _vm._v(" "),
              _c("user-image", {
                attrs: {
                  user: _vm.filters.users,
                  small: "",
                  editable: "",
                  quick: "",
                  "data-v-step": "filters:users",
                },
                on: { input: _vm.changeUsers },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }