var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "todos" },
    [
      _c("div", [
        _c("span", [_vm._v("DEADLINES")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "button",
              {
                staticClass: "button is-large",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.openTodoModal.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons" }, [
                  _vm._v("notifications"),
                ]),
                _vm._v(" Neue Aufgabe\n            "),
              ]
            ),
            _vm._v(" "),
            _c("user-image", {
              attrs: {
                user: _vm.filters["users"],
                small: "",
                editable: "",
                quick: "",
              },
              on: { input: _vm.changeUsers },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      [
        _c("div", { staticClass: "is-flex level-left" }, [
          _c("i", {
            staticClass: "fa fa-filter",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filters["_search"],
                expression: "filters['_search']",
              },
            ],
            staticClass: "input",
            attrs: { type: "text", placeholder: "Nach Aufgabe suchen" },
            domProps: { value: _vm.filters["_search"] },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.filters, "_search", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "buttons has-addons" },
            _vm._l(_vm.statuses, function (status) {
              return _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.bottom",
                      value: status.label,
                      expression: "status.label",
                      modifiers: { bottom: true },
                    },
                  ],
                  key: status.value,
                  staticClass: "button is-icon",
                  class: {
                    "is-primary": _vm.filters["statuses"].includes(
                      status.value
                    ),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.filterByStatus(status)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v(_vm._s(status.icon)),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("\n            Aufgaben in den nächsten\n            "),
          _c("div", { staticClass: "select" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters.days,
                    expression: "filters.days",
                  },
                ],
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.filters,
                      "days",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option", { attrs: { value: "3" } }, [_vm._v("3 Tagen")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "7" } }, [_vm._v("7 Tagen")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "30" } }, [_vm._v("30 Tagen")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "365" } }, [
                  _vm._v("12 Monaten"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.pagination.items))]),
        ]),
      ],
      _vm._v(" "),
      _vm.updateLoading || _vm.isLoading
        ? _c("loading")
        : _c(
            "div",
            { staticClass: "list" },
            [
              _vm.pagination.items > 0
                ? [
                    _vm._l(_vm.filteredTodos, function (todo) {
                      return _c("todo", {
                        key: todo.id,
                        attrs: {
                          todo: todo,
                          showUser: _vm.filters["users"].length > 1,
                        },
                        on: {
                          edit: (todo) => {
                            _vm.openTodoModal(todo)
                          },
                          editTodo: _vm.fetchTodos,
                        },
                      })
                    }),
                    _vm._v(" "),
                    _vm.pagination.items > 10
                      ? _c(
                          "div",
                          { staticClass: "level py" },
                          [
                            _c("div"),
                            _vm._v(" "),
                            _c("pagination", {
                              attrs: { simple: "" },
                              on: { input: _vm.fetchTodos },
                              model: {
                                value: _vm.pagination,
                                callback: function ($$v) {
                                  _vm.pagination = $$v
                                },
                                expression: "pagination",
                              },
                            }),
                            _vm._v(" "),
                            _c("div"),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : [
                    _c("div", { staticClass: "todo" }, [
                      _vm._v("Keine Deadlines vorhanden"),
                    ]),
                  ],
            ],
            2
          ),
      _vm._v(" "),
      _c("todo-form", { ref: "todoModal", attrs: { showOrder: "" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }