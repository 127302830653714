import {countries} from "@utilities/variables";
import {getOrganisationsFromStorage} from "@utilities/functions";

export default {
    props: {
        options: {
            type: Object,
            required: true,
            default: () => ({
                orderStatus: [],
                status: [],
            })
        },
    },

    data: function() {
        return {
            resources: {
                countries: countries,
                organisations: [],
            }
        }
    },

    methods: {
        getOrganisationsFromStorage
    },

    created: function () {
        this.resources.organisations = this.getOrganisationsFromStorage();
    },

    computed: {
        filterCountry: {
            get: function () { return this.resources.countries.find(country => country.label === this.filters.country) },
            set: function (country) { this.filters.country = country ? country.label : null }
        },

        filterOrganisation: {
            get: function () {
                if (this.filters.organisation) {
                    return this.resources.organisations.find(({id}) => this.filters.organisation === id)
                }
                return null;
            },
            set: function (value) {
                this.filters.organisation = value.id
            }
        },

        orderStatus: {
            get: function () {
                if (!!this.options.orderStatus){
                    return this.options.orderStatus
                        .filter(({key}) => this.filters.orderStatus.includes(key))
                } else {
                    return []
                }
            },
            set: function (value) {
                this.filters.orderStatus = value.map(({key}) => key)
            }
        },

        requestStatus: {
            get: function () {
                if (!!this.options.status){
                    return this.options.status
                        .filter(({key}) => this.filters.requestStatus.includes(key))
                } else {
                    return []
                }
            },
            set: function (value) {
                this.filters.requestStatus = value.map(({key}) => key)
            }
        },

        clientOfferStatuses: function () {
            return this.options.clientOfferStatuses || []
        },

        clientOfferStatus: {
            get: function () {
                return this.clientOfferStatuses
                    .find(status => status.key === this.filters['clientOfferStatus'])
            },

            set: function (value) {
                this.filters.clientOfferStatus = !!value ? value.key : null
            }
        }
    },
}
