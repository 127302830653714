<template>
    <layout>
        <div class="is-user-dashboard" v-if="!isGrantedBookKeeper">
            <div>
                <orders
                    v-on:refreshTodos="refreshTodos"
                />
                <todos
                    ref="todosComponent"
                />
            </div>
            <tour />
        </div>
    </layout>
</template>

<script>
import Todos from './Todos';
import Orders from './Orders';
import Tour from './Tour'
import Modal from '@components/Modal';
import {currentUser} from "@utilities/functions";

export default {
    components: {
        Modal,
        Todos,
        Orders,
        Tour
    },

    computed: {
        isGrantedBookKeeper: function() {
            let loggedInUser = currentUser();
            return loggedInUser && loggedInUser.roles && loggedInUser.roles.includes('ROLE_BOOK_KEEPER');
        },
    },

    methods: {
        refreshTodos: function(){
            this.$refs.todosComponent.fetchTodos();
        }
    },

    created () {
        if(this.isGrantedBookKeeper) {
            window.location.replace(`/invoices`);
        }
    }
}
</script>
