var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-white" },
    _vm._l(_vm.requests, function (request) {
      return _c("div", { key: request.id, staticClass: "row" }, [
        _vm._m(0, true),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell is-user-cell" },
          [
            _c(
              "user",
              _vm._b(
                {},
                "user",
                { value: request.order.user, row: request },
                false
              )
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cell is-smaller-cell" }, [
          _vm._v(_vm._s(request.order.id)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell" }, [
          _vm._v(_vm._s(request.order.client.shortName)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell is-one-quarter" },
          [
            request.agency && request.info.provider.name !== ""
              ? [
                  _vm._v(
                    _vm._s(request.agency.name) +
                      " - " +
                      _vm._s(request.info.provider.name)
                  ),
                ]
              : request.agency && request.info.provider.name === ""
              ? [_vm._v(_vm._s(request.agency.name) + " - No Name")]
              : [_vm._v(_vm._s(request.info.provider.name))],
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cell is-smaller-cell" }, [
          _vm._v(_vm._s(_vm._f("fullYear")(request.startAt))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell is-smaller-cell" }, [
          _vm._v(_vm._s(_vm._f("fullYear")(request.endAt))),
        ]),
        _vm._v(" "),
        _vm.contingent(request)
          ? _c(
              "div",
              { staticClass: "cell is-medium-cell" },
              [
                _c(
                  "contingent",
                  _vm._b(
                    {},
                    "contingent",
                    { row: request.order, value: _vm.contingent(request) },
                    false
                  )
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell" },
          [
            _c(
              "status",
              _vm._b(
                {},
                "status",
                {
                  row: request.order,
                  value: request.order.currentStatus,
                  options: _vm.options,
                },
                false
              )
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "cell" }, [
          _vm._v(_vm._s(_vm.getPax(request, "reservedAmount")) + " "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cell" }, [
          _vm._v(_vm._s(_vm.getPax(request, "askedAmount"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell" },
          [
            _c(
              "status",
              _vm._b(
                {},
                "status",
                {
                  row: request,
                  value: request.requestStatus.status,
                  options: _vm.options,
                },
                false
              )
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "cell is-medium-cell is-relative" },
          [
            _c(
              "deadline",
              _vm._b(
                {},
                "deadline",
                { row: request, value: request.optionTodo.dueDate },
                false
              )
            ),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cell is-user-cell" }, [
      _c("i", { staticClass: "material-icons" }, [
        _vm._v("subdirectory_arrow_right"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }